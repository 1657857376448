import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import DialogContentText from '@material-ui/core/DialogContentText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(0)
  }
});

class AppearanceTab extends Component {
  render() {
    // Styling
    const { classes } = this.props;

    const { layoutDirection, onLayoutDirectionChange } = this.props;

    return (
      <React.Fragment>
        <DialogContentText classes={{ root: classes.root }}>
          Customize your Reader experience here.
        </DialogContentText>

        <FormControl fullWidth margin="normal">
          <InputLabel>Reader layout</InputLabel>

          <Select onChange={(event) => onLayoutDirectionChange(event.target.value)} value={layoutDirection}>
            <MenuItem value="ltr">Board on the right</MenuItem>
            <MenuItem value="rtl">Board on the left</MenuItem>
          </Select>
        </FormControl>
      </React.Fragment>
    );
  }
}

AppearanceTab.propTypes = {
  classes: PropTypes.object.isRequired,

  layoutDirection: PropTypes.string.isRequired,

  onLayoutDirectionChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(AppearanceTab);
